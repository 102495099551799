import {forwardRef} from 'react'
import {cn} from 'src/app/utils/cn-utils'
import GIcon from '../../../Icon/GIcon'
import {FormError} from '../FormError'
import {FormTextProps} from './Text.types'
import clsx from 'clsx'

export const FormText = forwardRef<HTMLInputElement, FormTextProps>(
  (
    {
      className,
      inputClassName,
      error,
      touched,
      type = 'text',
      prependIcon,
      prependInnerIcon,
      appendIcon,
      appendInnerIcon,
      prependClassname,
      prependInnerClassname,
      appendClassname,
      appendInnerClassname,
      clickPrependIcon,
      clickPrependInnerIcon,
      clickAppendIcon,
      clickAppendInnerIcon,
      prependNode,
      appendNode,
      alertInformation,
      prefix,
      suffix,
      ...rest
    },
    ref // forwardRef menerima ref sebagai argumen kedua
  ) => {
    return (
      <div className={className}>
        <div className='relative flex items-center'>
          {prependNode && <div className={cn('ml-2')}>{prependNode}</div>}
          {prependIcon && (
            <GIcon
              icon={prependIcon}
              className={cn('cursor-pointer mr-2 w-5 h-5', prependClassname)}
              onClick={clickPrependIcon}
            />
          )}
          <div className='relative flex items-center flex-grow'>
            {prependInnerIcon && (
              <GIcon
                icon={prependInnerIcon}
                className={cn('cursor-pointer absolute left-4 w-5 h-5', prependInnerClassname)}
                onClick={clickPrependInnerIcon}
              />
            )}
            {prefix && (
              <div
                data-testid='formtext-prefix'
                className='absolute w-5 h-5 font-medium left-4 text-fs-9'
              >
                {prefix}
              </div>
            )}
            <input
              ref={ref}
              type={type}
              className={cn(
                'border border-solid border-[#D0D5DD] bg-white h-[44px] focus:border-4 focus:border-[#9CD0F5] focus:border-opacity-75',
                touched && error
                  ? 'border-danger focus:border focus:border-solid focus:border-danger'
                  : '',
                prependInnerIcon || prefix ? 'pl-10' : '',
                appendInnerIcon || suffix ? 'pr-14' : '',
                inputClassName
              )}
              {...rest}
            />
            {appendInnerIcon
              ? null
              : alertInformation
              ? null
              : touched &&
                error && (
                  <div className='absolute right-2 w-5 h-5'>
                    <GIcon
                      icon='IconWarningDanger'
                      className={clsx(
                        'cursor-pointer absolute right-4 w-5 h-5',
                        appendInnerClassname
                      )}
                    />
                  </div>
                )}
            {suffix && (
              <div
                data-testid='formtext-suffix'
                className='absolute w-5 h-5 font-medium right-4 text-fs-9'
              >
                {suffix}
              </div>
            )}
            {appendInnerIcon && (
              <GIcon
                icon={appendInnerIcon}
                className={cn('cursor-pointer absolute right-4 w-5 h-5', appendInnerClassname)}
                onClick={clickAppendInnerIcon}
              />
            )}
          </div>
          {appendIcon && (
            <GIcon
              icon={appendIcon}
              className={cn('cursor-pointer ml-2 w-5 h-5', appendClassname)}
              onClick={clickAppendIcon}
            />
          )}
          {appendNode && <div className={cn('ml-2')}>{appendNode}</div>}
        </div>
        {touched && error && <FormError>{error}</FormError>}
      </div>
    )
  }
)
