import { createSlice } from "@reduxjs/toolkit";

type KeyboardState = {
  showKeyboard: boolean;
};

const initialState: KeyboardState = {
  showKeyboard: false,
};

const keyboardSlice = createSlice({
  name: "keyboard",
  initialState,
  reducers: {
    setShowKeyboard: (state, action) => {
      state.showKeyboard = action.payload;
    },
  },
});

export const { setShowKeyboard } = keyboardSlice.actions;
export default keyboardSlice.reducer;
