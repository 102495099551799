import React, {useEffect, useState} from 'react'
import {checkJson} from 'src/app/utils/cek-json-utils'
import {handleResponse} from 'src/setup/hooks/socketInterceptor'
import setupWebsocket from 'src/setup/websocket/SetupWebsocket'
import {IQuickService, ISalesList} from '../Table/models/Table.model'
import Pagination from '../../Libs/Table/Pagination'
import {useHistory} from 'react-router-dom'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import OrderRedux from 'src/app/modules/order/redux/OrderRedux'
import {IUser} from 'src/app/models/api.types'
import {RootState} from 'src/setup'
import {formatRupiah} from 'src/app/utils/input-utils'

const SalesList = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [salesList, setSalesList] = useState<ISalesList[]>([])
  const user: IUser = useSelector<RootState>(
    ({auth}) => auth?.user,
    shallowEqual
  ) as unknown as IUser

  const getActiveTransaction = () => {
    const wsActiveTransaction = setupWebsocket('active_transaction', {
      type: 'GET',
    })
    wsActiveTransaction.onmessage = (event) => {
      const response = checkJson(event.data) ? JSON.parse(event.data) : ''
      if (response?.response_schema?.response_code === '200') {
        setSalesList(response.response_output.list.content)
      } else if (event) {
        handleResponse(event)
      }
    }
  }

  const getOrderByTable = (item: ISalesList) => {
    const payloadGetOrder = {
      type: 'POST',
      data: {
        table_id: item?.table_id,
      },
    }
    const wsGetOrder = setupWebsocket('get_order_by_table', payloadGetOrder)
    wsGetOrder.onmessage = (event) => {
      const response = checkJson(event.data) ? JSON.parse(event.data) : ''
      if (response?.response_schema?.response_code === '200') {
        const detail: any = response.response_output.detail
        dispatch(
          OrderRedux.actions.BookTableOnly(
            null,
            item?.total_pax,
            Number(item?.table_id),
            item?.table_name,
            'Dine In'
          )
        )
        const tableStatus = detail?.table_layout[0]?.table_status
        history.push('/order', {tableStatus, tableBook: detail?.pos_table_books})
      } else {
        handleResponse(event)
      }
    }
  }

  const getQuickService = (item: ISalesList) => {
    const payload = {
      type: 'POST',
      data: {
        pos_id: item?.pos_id,
      },
    }

    setupWebsocket('quick_service_detail', payload).onmessage = (event) => {
      const response = checkJson(event.data) ? JSON.parse(event.data) : ''
      if (response?.response_schema?.response_code === '200') {
        const detail: IQuickService = response.response_output.detail
        dispatch(
          OrderRedux.actions.UpdateQuickService(
            detail.sales_mode_id,
            item?.total_pax || 0,
            'Take Away',
            item?.pos_id,
            ''
          )
        )
        history.push('/order')
      } else {
        handleResponse(event)
      }
    }
  }

  useEffect(() => {
    getActiveTransaction()
  }, [])
  return (
    <div className='flex flex-col grow h-full bg-white rounded-lg border border-[#EAECF0]'>
      <div className='flex flex-col h-full'>
        <div className='flex-none px-5 py-6 text-lg font-poppins border-b border-[#EAECF0]'>
          Sales List
        </div>
        <div className='flex-1 flex-col h-full overflow-y-auto'>
          <table className='min-w-full'>
            <thead className='border-b border-[#EAECF0] bg-[#F9FAFB] text-sm text-left text-[#667085]'>
              <tr>
                <td className='px-5 py-6'>Transaction Number</td>
                <td className='px-5 py-6'>Section</td>
                <td className='px-5 py-6'>Table</td>
                <td className='px-5 py-6'>Pax Total</td>
                <td className='px-5 py-6'>Member</td>
                <td className='px-5 py-6'>Additional Info</td>
                <td className='px-5 py-6'>Grand Total</td>
              </tr>
            </thead>
            <tbody>
              {salesList &&
                salesList
                  ?.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage)
                  .map((item, index) => (
                    <tr
                      data-cy={`sales-list-item-${index}`}
                      key={`sales-list-${item?.doc_no}`}
                      className='border-b border-[#EAECF0] text-xs text-left text-[#101828] hover:cursor-pointer'
                      onClick={() => {
                        if (user) {
                          if (item?.table_id) {
                            getOrderByTable(item)
                          } else {
                            getQuickService(item)
                          }
                        }
                      }}
                    >
                      <td className='px-5 py-6'>{item?.doc_no}</td>
                      <td className='px-5 py-6'>{item?.section_name}</td>
                      <td className='px-5 py-6'>{item?.table_name}</td>
                      <td className='px-5 py-6'>{item?.total_pax}</td>
                      <td className='px-5 py-6'>{item?.member_status}</td>
                      <td className='px-5 py-6'>
                        {item?.additional_info === '' ? '-' : item?.additional_info}
                      </td>
                      <td className='px-5 py-6'>{formatRupiah(item?.grand_total)}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
          <div
            className={`h-[50vh] flex-col align-middle flex flex-1 justify-center self-center ${
              salesList?.length === 0 ? 'visible' : 'hidden'
            }`}
          >
            <span className='self-center items-center'>No data</span>
          </div>
          <div className={`mb-8 ${salesList?.length === 0 && 'hidden'}`}>
            <Pagination
              maxData={salesList?.length ?? 0}
              setPage={setCurrentPage}
              page={currentPage}
              setPerPage={(e) => {
                setCurrentPage(0)
                setRowsPerPage(e)
              }}
              size={rowsPerPage ?? 0}
              type='TYPE_1'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalesList
