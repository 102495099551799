import {FileRejection} from 'react-dropzone'
import {PageToasts} from '../context/ToastContext'

export const phoneRegExp = /^62\d{8,}$/gm
export const emailRegExp = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/

export const isNumeric = (text: string) => Boolean(text.match(/^\d+$/))
export const trimNonNumeric = (text: string) => text.replace(/\D/g, '')
export const trimPhoneNumber = (text: string, prefix: string) => {
  let result = text
  if (text.startsWith('+') && text.length >= prefix.length) result = text.slice(prefix.length + 1)
  if (result.startsWith('62')) {
    result = result.slice(2)
  }
  if (result[0] === '0') {
    result = result.slice(1)
  }
  return trimNonNumeric(result)
}

export const needAddPrefix = (text: string) => !text.startsWith('62') || text.startsWith('0')
export const addPhonePrefix = (text: string) => {
  let result = text
  if (result[0] === '0') {
    result = result.slice(1)
  }
  return `62${trimNonNumeric(result)}`
}

export const trimPhoneBasic = (text: string) => {
  let result = text
  if (result.startsWith('62')) {
    result = result.slice(2)
  }
  return trimNonNumeric(result)
}

export const addThousandSeparator = (text: string) =>
  text.replace(/(?<=\d)(?=(\d{3})+(?!\d))/g, '.')

export const formatMoney = (number?: number, currency = '') =>
  `${number && number < 0 ? '-' : ''}${currency}${Math.abs(number ?? 0).toLocaleString('id-ID')}`

export const formatRupiah = (number?: number, invalid?: boolean, currency = 'Rp') => {
  if (invalid) return `${currency}-`
  return formatMoney(number, currency)
}

export const formatRupiahSpaced = (number?: number, invalid?: boolean, currency = 'Rp ') =>
  formatRupiah(number, invalid, currency)

export const appendFormArray = (
  formData: FormData,
  name: string,
  props: (string | undefined)[]
) => {
  props.forEach((value) => {
    if (value) formData.append(`${name}[]`, value)
  })
}

export const appendAllFormData = (
  formData: FormData,
  names: {key: string; name: string}[],
  inputs: Record<any, any>
) => {
  names.forEach((name) => {
    const data = inputs[name.key]
    if (data || typeof data === 'boolean') {
      if (Array.isArray(data)) appendFormArray(formData, name.name, data)
      else formData.append(name.name, data)
    }
  })
}

export const deleteFilesFormData = (
  formData: FormData,
  names: {key?: string; name: string}[],
  inputs: Record<any, any>,
  delete_field: string = 'delete_files'
) => {
  names.forEach((name) => {
    if (!inputs[name.name] && (!name.key || !!inputs[name.key])) {
      formData.append(`${delete_field}[]`, name.name)
    }
  })
}

export const appendAllRecordData = (
  formData: Record<string, string>,
  names: {key: string; name: string}[],
  inputs: Record<any, any>
) => {
  names.forEach((name) => {
    const data = inputs[name.key]
    if (data || typeof data === 'boolean') {
      formData[name.name] = data
    }
  })
}

export const copyText = (text: string, listener: (toast: PageToasts) => void) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      listener({scheme: 'info', text: 'Text copied.', fixed: true})
    })
    .catch(() => {
      listener({scheme: 'danger', text: 'Failed to copy text.', fixed: true})
    })
}

export const openWhatsapp = (phone: string) => {
  window.open(
    `https://api.whatsapp.com/send?phone=62${encodeURI(trimPhoneNumber(phone, '62'))}`,
    '_blank'
  )
}

export const createBase64 = (file: File | Blob) => {
  const _URL = window.URL || window.webkitURL
  return _URL.createObjectURL(file)
}

export const downloadFile = (url: string, filename: string) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const getExtensionFromMime = (mime: string) => {
  switch (mime) {
    case 'image/png':
      return 'png'
    case 'image/gif':
      return 'gif'
    case 'text/csv':
      return 'csv'
    default:
      return 'jpg'
  }
}

export const humanFileSize = (b: number): string => {
  let u = 0,
    s = 1024
  while (b >= s || -b >= s) {
    b /= s
    u++
  }
  return (u ? b.toFixed(1) + ' ' : b) + ' KMGTPEZY'[u] + 'B'
}

export const validateBlob = async ({
  result,
  error,
  field,
  preview_field,
  setFieldTouched,
  setFieldError,
  setFieldValue,
  png = true,
}: {
  result?: Blob | null
  error?: any
  field: string
  preview_field?: string
  setFieldTouched?: Function
  setFieldError: Function
  setFieldValue: Function
  png?: boolean
}) => {
  if (error || !result) {
    setFieldError(field, error)
    if (setFieldTouched) setFieldTouched(field, true)
    return
  }
  await setFieldValue(field, new File([result], png ? 'image.png' : 'image.jpg'))
  await setFieldValue(preview_field, createBase64(result), true)
}

//maxSize: in MB, dimension: in pixel

export const validateImage = ({
  image,
  rejects,
  field,
  preview_field,
  setFieldTouched,
  setFieldError,
  setFieldValue,
  maxSize,
  dimension,
}: {
  image?: File
  rejects?: FileRejection[]
  field: string
  preview_field?: string
  setFieldTouched?: Function
  setFieldError: Function
  setFieldValue: Function
  maxSize?: number
  dimension?: {
    width: number
    height: number
  }
}) => {
  const setFieldTouchedFn = (field: string, setFieldTouched?: Function) => {
    if (setFieldTouched) setFieldTouched(field, true)
  }

  if (!image) {
    setFieldTouchedFn(field, setFieldTouched)
    if (rejects && rejects.length > 0) setFieldError(field, `File type not supported.`)
    return
  }
  if (maxSize && image.size / 1024 / 1024 > maxSize) {
    setFieldTouchedFn(field, setFieldTouched)
    setFieldError(field, `Max upload size is ${maxSize}MB.`)
    return
  }
  const _URL = window.URL || window.webkitURL
  const img = new Image()
  let objectUrl = _URL.createObjectURL(image)
  img.onload = async function () {
    if (
      dimension &&
      (Number((this as HTMLObjectElement).width) !== dimension.width ||
        Number((this as HTMLObjectElement).height) !== dimension.height)
    ) {
      setFieldError(field, `Image size not ${dimension.width}x${dimension.height}`)
    } else {
      setFieldError(field, undefined)
      setFieldTouchedFn(field, setFieldTouched)
      await setFieldValue(preview_field, objectUrl)
      await setFieldValue(field, image, true)
    }
    img.remove()
  }
  img.src = objectUrl
}

export const validateFile = ({
  file,
  rejects,
  field,
  preview_field,
  setFieldError,
  setFieldValue,
  maxSize,
}: {
  file?: File
  rejects?: FileRejection[]
  field: string
  preview_field?: string
  setFieldError: Function
  setFieldValue: Function
  maxSize?: number
}) => {
  if (!file) {
    if (rejects && rejects.length > 0) setFieldError(field, `File type not supported`)
    return
  }
  if (maxSize && file.size / 1024 / 1024 > maxSize) {
    setFieldError(field, `Max upload size is ${maxSize}MB`)
    return
  }
  const _URL = window.URL || window.webkitURL
  let objectUrl = _URL.createObjectURL(file)
  setFieldValue(field, file)
  setFieldValue(preview_field, objectUrl)
  return objectUrl
}

export const trimString = (word: string, max: number) => {
  const trimmedWord = word?.length > max ? `${word.substring(0, max)}...` : word
  return trimmedWord
}

export const simulateInsertText = (text: string) => {
  const activeElement = document.activeElement as HTMLInputElement | HTMLTextAreaElement

  if (
    activeElement &&
    (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA')
  ) {
    const start = activeElement.selectionStart
    const end = activeElement.selectionEnd

    if (start !== null && end !== null) {
      const currentValue = activeElement.value

      const newValue = currentValue.slice(0, start) + text + currentValue.slice(end)
      const newCursorPos = start + text.length

      activeElement.value = newValue
      activeElement.setSelectionRange(newCursorPos, newCursorPos)

      const inputEvent = new Event('input', {bubbles: true})
      activeElement.dispatchEvent(inputEvent)

      requestAnimationFrame(() => activeElement.focus())

      return newValue
    }
  }
}

export const simulateBackspace = (setValue: any) => {
  const activeElement = document.activeElement as HTMLInputElement | HTMLTextAreaElement

  if (
    activeElement &&
    (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA')
  ) {
    const start = activeElement.selectionStart
    const end = activeElement.selectionEnd

    if (start !== null && end !== null) {
      const currentValue = activeElement.value
      let newValue: string
      let newCursorPos: number

      if (start === end && start > 0) {
        newValue = currentValue.slice(0, start - 1) + currentValue.slice(end)
        newCursorPos = start - 1
      } else if (start !== end) {
        newValue = currentValue.slice(0, start) + currentValue.slice(end)
        newCursorPos = start
      } else {
        return
      }

      activeElement.value = newValue
      setValue(newValue)
      activeElement.setSelectionRange(newCursorPos, newCursorPos)
      const inputEvent = new Event('input', {bubbles: true})
      activeElement.dispatchEvent(inputEvent)

      requestAnimationFrame(() => activeElement.focus())

      return newValue
    }
  }
}
export const generateArray = (input: number): number[] => {
  const start = Math.floor((input - 1) / 10) * 10 + 1
  return Array.from({length: 10}, (_, i) => start + i)
}

export const cleanNumber = (input: any) => {
  const sanitizedValue = input
    .split('')
    .filter((char: any) => '0123456789'.includes(char))
    .join('')
  return sanitizedValue
}

export const formatInsufficientMessage = (text: string) => {
  const match = text.match(/Insufficient qty for: (.+)\((\d+)\)/);
  if (!match) return text;

  const [, menuName, limitQty] = match;
  return `Maximum quantity for ${menuName} is ${limitQty}.`;
}
