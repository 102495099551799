import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {FC, Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {LayoutProvider} from './context/LayoutContext'
import {LayoutSplashScreen} from './context/SplashScreenContext'
import AuthInit from './modules/auth/redux/AuthInit'
import {Routes} from './routers/Routes'
import ModalWarning from './components/Widgets/ModalCollection/WarningModal'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import setupWebsocket from 'src/setup/websocket/SetupWebsocket'
import AuthRedux from './modules/auth/redux/AuthRedux'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

type Props = {
  basename?: string
}

const App: FC<Props> = ({basename}) => {
  const force_logout = useSelector<RootState>(({auth}) => auth?.force_logout)
  const dispatch = useDispatch()

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter
        basename='/pos'
        getUserConfirmation={() => {
          /* Empty callback to block the default browser prompt */
        }}
      >
        <QueryClientProvider client={queryClient}>
          <LayoutProvider>
            <ModalWarning
              show={Boolean(force_logout)}
              onClick={() => {
                setupWebsocket('logout', {type: 'GET'})
                dispatch(AuthRedux.actions.logout())
              }}
              header={''}
              size='sm'
              subHeader='User is being used on another device'
              type={'WARNING'}
              positiveLabel='Ok'
            />
            <AuthInit>
              <Routes />
            </AuthInit>
          </LayoutProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export default App
