import {checkJson} from 'src/app/utils/cek-json-utils'
import {getAuthState} from './getAuthState'
import AuthRedux from 'src/app/modules/auth/redux/AuthRedux'

const {dispatch} = getAuthState()

export const handleResponse = (event: any) => {
  const res = checkJson(event.data) ? JSON.parse(event.data) : ''
  if (
    res.response_schema?.response_code === '401' &&
    Boolean(
      ['Unauthorized'].includes(String(res.response_schema?.response_message)) ||
        String(res.response_schema?.response_message).includes('Expired JWT Token')
    )
  ) {
    dispatch(AuthRedux.actions.logout())
  }
}
