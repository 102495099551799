import { configureStore } from '@reduxjs/toolkit'
import {combineReducers} from 'redux'
import {all} from 'redux-saga/effects'

import AuthRedux from 'src/app/modules/auth/redux/AuthRedux'
import OrderRedux from 'src/app/modules/order/redux/OrderRedux'
import LocalizationRedux from 'src/app/plugins/i18n/localization-redux'
import keyboardReducer from 'src/app/components/Widgets/Keyboard/KeyboardRedux/keyboardSlice'

export const rootReducer = () =>
  combineReducers({
    keyboard: keyboardReducer,
    auth: AuthRedux.reducer(),
    localization: LocalizationRedux.reducer(),
    order: OrderRedux.reducer()
  })
  export const store = configureStore({
    reducer: rootReducer,
  });

export type RootState = ReturnType<ReturnType<typeof rootReducer>>

export function* rootSaga() {
  yield all([AuthRedux.saga()])
}
