import {FC, MouseEventHandler, ReactNode} from 'react'
import {GModal} from '../../Libs'
import OrderModalFooter from './OrderModalFooter'
import OrderModalHeader from './OrderModalHeader'
import {usePermission} from 'src/app/hooks/usePermission'
import {Permissions} from 'src/app/utils/permission'

interface BasicModalProps {
  show: boolean
  handleActionOne?: MouseEventHandler
  handleActionTwo?: MouseEventHandler
  handleClose: MouseEventHandler
  header: string
  selectedTable?: string | number | null
  children?: ReactNode
  positiveLabel?: string
  negativeLabel?: string
  size?: 'xl' | 'lg' | 'md' | 'sm'
  loading?: boolean
  isBtnCloseHidden?: boolean
  statusOrder?: 'DINE IN' | 'TAKE AWAY' | ''
  disabledButton?: boolean
  isShowNegativeButton?: boolean
}

const OrderModal: FC<BasicModalProps> = ({
  show,
  handleActionOne,
  handleActionTwo,
  handleClose,
  header,
  selectedTable,
  positiveLabel,
  negativeLabel,
  size = 'md',
  loading,
  children,
  isBtnCloseHidden,
  statusOrder,
  disabledButton,
  isShowNegativeButton = true,
}) => {
  const {checkPermission} = usePermission()

  return (
    <GModal show={show} onHide={handleClose} size={size}>
      <OrderModalHeader
        header={header}
        subHeader={selectedTable}
        status={statusOrder}
        handleClose={handleClose}
        isBtnCloseHidden={isBtnCloseHidden}
      />
      <div className='px-6 py-5 min-h-[100px] max-h-[70vh] text-neutral-80 w-full'>{children}</div>
      {checkPermission(Permissions.TABLE_CREATE_ORDER) && (
        <OrderModalFooter
          handleClose={handleActionOne}
          handleSuccess={handleActionTwo}
          positiveLabel={positiveLabel}
          negativeLabel={negativeLabel}
          loading={loading}
          disabled={disabledButton}
          isShowNegativeButton={isShowNegativeButton}
        />
      )}
    </GModal>
  )
}

export default OrderModal
