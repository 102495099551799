import {FC, useMemo} from 'react'
import InlineSVG from 'react-inlinesvg'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'

export type PaginationType = 'TYPE_1' | 'TYPE_2'

export type PaginationProps = {
  size: number
  setPerPage: (number: number) => void
  page: number
  setPage: (number: number) => void
  maxData: number
  type?: PaginationType
}

const Pagination: FC<PaginationProps> = ({
  size,
  setPerPage,
  page,
  setPage,
  maxData,
  type = 'TYPE_1'
}) => {
  const pageNum = useMemo(() => Math.ceil(maxData / size), [maxData, size])

  const pageList = useMemo(() => {
    const result: (number | undefined)[] = []
    if (pageNum === -1) return result
    const normalizedPage = Math.min(pageNum, Math.max(-1, page))
    if (pageNum < 8)
      for (let i = 0; i <= pageNum - 1; i++) {
        result.push(i)
      }
    else {
      if (normalizedPage > 3) result.push(0, undefined, Math.min(normalizedPage, pageNum - 3) - 1)
      else result.push(0, 1, 2, 3)
      if (normalizedPage + 2 < pageNum - 1) {
        if (normalizedPage > 3) result.push(normalizedPage)
        result.push(Math.max(normalizedPage, 3) + 1, undefined, pageNum - 1)
      } else result.push(pageNum - 3, pageNum - 2, pageNum - 1)
    }
    return result
  }, [pageNum, page])

  return (
    <div className='flex flex-wrap pt-6 px-9'>
      <div className='flex items-center w-full md:w-auto'>
        <div className='relative '>
          {type === 'TYPE_1' && (
            <div>
              <select
                className={`pr-13 text-neutral-70 w-[72px] text-fs-10 py-2 px-3 bg-white border border-solid border-[#D0D5DD]`}
                autoComplete='off'
                value={size}
                onChange={(e) => setPerPage(Number(e.currentTarget.value))}
              >
                <option>5</option>
                <option>10</option>
                <option>20</option>
                <option>50</option>
                <option>100</option>
              </select>
              <label className='text-xs pl-2'>Rows Per page</label>
            </div>
          )}

          {type === 'TYPE_2' && (
            <div className={`font-semibold text-fs-10 text-neutral-70`}>
              Display {page * size + 1} -{' '}
              {(page + 1) * size > maxData ? maxData : (page + 1) * size} to {maxData} entries
            </div>
          )}
        </div>
      </div>

      {pageList.length > 0 ? (
        <ul className='flex justify-end flex-1 p-0 m-0 list-none flex-nowrap'>
          <li className='mr-3'>
            <button
              data-cy={'prev-page'}
              onClick={() => setPage(page - 1)}
              className={`text-neutral-60 font-medium text-fs-10 w-8 h-8 py-2.5 px-1 transition-all duration-300 rounded ${
                page === 0 ? 'cursor-not-allowed' : 'hover:bg-primary-light-1 hover:text-black'
              }`}
              disabled={page === 0}
            >
              {page === 0 ? (
                <InlineSVG
                  src={toAbsoluteUrl('/media/icons/IconPaginationLeft.svg')}
                  className='w-full h-full'
                />
              ) : (
                <InlineSVG
                  src={toAbsoluteUrl('/media/icons/IconLeftBlack.svg')}
                  className='w-full h-full'
                />
              )}
            </button>
          </li>
          {pageList.map((x, i) =>
            x === undefined ? (
              <li className='mr-3' key={`${x ? x : i + i}-dot`}>
                <button disabled className='font-medium text-neutral-60 text-fs-10'>
                  ...
                </button>
              </li>
            ) : (
              <li className='mr-3' key={x}>
                <button
                  onClick={() => setPage(x)}
                  className={`flex justify-center items-center transition-all duration-300 rounded h-8 w-8 font-semibold text-fs-10 z-30 ${
                    page !== x
                      ? 'bg-none text-neutral-90 hover:bg-primary-light-1 hover:text-black dark:text-black dark:bg-neutral-40 dark:hover:bg-primary-light-2 dark:hover:text-neutral-80'
                      : 'bg-[#FEF7F3] text-[#475467] border border-[#F08575]'
                  }`}
                >
                  {x + 1}
                </button>
              </li>
            )
          )}
          <li>
            <button
              data-cy={'next-page'}
              onClick={() => setPage(page + 1)}
              className={`text-neutral-60 font-medium text-fs-10 w-8 h-8 py-2.5 px-1 transition-all duration-300 rounded ${
                page === pageNum - 1
                  ? 'cursor-not-allowed'
                  : 'hover:bg-primary-light-1 hover:text-black'
              }`}
              disabled={page === pageNum - 1}
            >
              {pageNum - 1 === page ? (
                <InlineSVG
                  src={toAbsoluteUrl('/media/icons/IconPaginationRight.svg')}
                  className='w-full h-full'
                />
              ) : (
                <InlineSVG
                  src={toAbsoluteUrl('/media/icons/IconRigthBlack.svg')}
                  className='w-full h-full'
                />
              )}
            </button>
          </li>
        </ul>
      ) : null}
    </div>
  )
}

export default Pagination
