import clsx from 'clsx'
import {useMemo} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {useMasterLayout} from 'src/app/context/MasterContext'
import {RootState} from 'src/setup'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {ModulePermissionQuery} from 'src/app/modules/permissions/models/Role.model'
import {User} from 'src/app/modules/permissions/models/User.model'
import TableListScreens from 'src/app/modules/table-list/Screeens'
import SalesRecapScreens from 'src/app/modules/sales-recapitulation/Screens'
import MemberScreens from 'src/app/modules/member/Screens'
import DayStartEndScreens from 'src/app/modules/day-start-end/Screens'
import ShiftLogScreens from 'src/app/modules/shift-log/Screens'
import ToolsScreens from 'src/app/modules/tools/Screens'
import BranchScreens from 'src/app/modules/branch/Screens'
import {usePermission} from 'src/app/hooks/usePermission'
import {Permissions} from 'src/app/utils/permission'

export interface Menu {
  id?: string
  to?: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  children?: Menu[]
  module_permissions?: ModulePermissionQuery
  activePath?: string
  badge?: string
  badgeCircle?: boolean
  hidden?: boolean
  disabled?: boolean
}

const useMenus = (user?: User): Menu[] => {
  const {checkPermission} = usePermission()
  return useMemo(
    () =>
      [
        {
          children: [
            {
              to: TableListScreens.TABLE.PATH,
              icon: '/media/icons_v2/swm/curved/list-right.svg',
              title: TableListScreens.TABLE.TITLE,
              module_permissions: TableListScreens.TABLE.PERMISSION,
            },
            checkPermission(Permissions.SALES_TODAY) && {
              to: SalesRecapScreens.SALES.PATH,
              icon: '/media/icons_v2/swm/curved/pinpaper-filled.svg',
              title: SalesRecapScreens.SALES.TITLE,
            },
            checkPermission(Permissions.MEMBER_LIST) && {
              to: MemberScreens.MEMBER.PATH,
              icon: '/media/icons_v2/swm/curved/users.svg',
              title: MemberScreens.MEMBER.TITLE,
            },
            checkPermission(Permissions.SHIFT_VIEW_CURRENT) && {
              to: DayStartEndScreens.DAYSTARTEND.PATH,
              icon: '/media/icons_v2/swm/curved/clock.svg',
              title: DayStartEndScreens.DAYSTARTEND.TITLE,
            },
            checkPermission(Permissions.SHIFT_LOG_LIST) && {
              to: ShiftLogScreens.SHIFT_LOG.PATH,
              icon: '/media/icons_v2/swm/curved/ticket.svg',
              title: 'Shift Log',
            },
            checkPermission(Permissions.BRANCH_MENU_LIST) && {
              to: BranchScreens.BRANCH.PATH,
              icon: '/media/icons_v2/swm/curved/list-menu-branch.svg',
              title: 'Branch',
            },
            {
              to: ToolsScreens.TOOLS.PATH,
              icon: '/media/icons_v2/swm/curved/settings.svg',
              title: 'Tools',
            },
            {
              to: '/station-management',
              icon: '/media/icons_v2/swm/curved/inbox.svg',
              title: 'Station Management',
            },
          ],
        },
      ] as Menu[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  )
}

export const GeneratedMenu: React.FC<{menu: Menu}> = ({menu}) => {
  const {minimize} = useMasterLayout()

  if (!menu.to) {
    if (!menu.children || menu.children.length === 0) return null
    return (
      <>
        <div className='block p-0'>
          <div className='px-6 pt-8 pb-2'>
            <span
              className={clsx('tracking-widest text-white uppercase text-fs-10', {
                hidden: minimize,
              })}
            >
              {menu.title}
            </span>
          </div>
        </div>
        {menu.children?.map((child) => {
          return <GeneratedMenu menu={child} key={child.id} />
        })}
      </>
    )
  }
  if (!menu.children)
    return (
      <AsideMenuItem
        to={menu.to}
        icon={menu.icon}
        title={menu.title}
        fontIcon={menu.fontIcon}
        hasBullet={menu.hasBullet}
        activePath={menu.activePath}
        badge={menu.badge}
        badgeCircle={menu.badgeCircle}
        disabled={menu.disabled}
      />
    )

  return (
    <AsideMenuItemWithSub
      to={menu.to}
      title={menu.title}
      fontIcon={menu.fontIcon}
      icon={menu.icon}
      activePath={menu.activePath}
      hasBullet={menu.hasBullet}
      disabled={menu.disabled}
    >
      {menu.children.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </AsideMenuItemWithSub>
  )
}

const filterMenus = (
  menus: Menu[] | undefined,
  predicate: (menu: Menu) => boolean
): Menu[] | undefined => {
  const result = menus?.map((menu, index) => ({
    ...menu,
    id: String(index),
    children: filterMenus(menu.children, predicate),
  }))
  return result?.filter((menu) => (!menu.children || menu.children.length > 0) && predicate(menu))
}

const AsideMenuData: React.FC = () => {
  const user: User = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as User
  const menus = useMenus(user)

  const generated = useMemo(() => filterMenus(menus, (menu) => !Boolean(menu.hidden)), [menus])
  return (
    <>
      {generated?.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </>
  )
}

export default AsideMenuData
