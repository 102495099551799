export const hasPermission = (userPermissions: string[], requiredPermission: string) => {
  return userPermissions.includes(requiredPermission);
};

export enum Permissions {
  MEMBER_LIST = "MEMBER.MEMBER_LIST",

  TABLE_CREATE_ORDER = "TABLE_LIST.CREATE_ORDER",
  TABLE_CANCEL_ORDER = "TABLE_LIST.CANCEL_ORDER",
  TABLE_MERGE_TABLE = "TABLE_LIST.MERGE_TABLE",
  TABLE_MOVE_TABLE = "TABLE_LIST.MOVE_TABLE",
  TABLE_MOVE_ITEM = "TABLE_LIST.MOVE_ITEM",
  TABLE_LINK_TABLE = "TABLE_LIST.LINK_TABLE",
  TABLE_CANCEL_TABLE = "TABLE_LIST.CANCEL_TABLE",
  TABLE_SAVE_PAYMENT = "TABLE_LIST.SAVE_PAYMENT",
  TABLE_ADD_PROMOTION = "TABLE_LIST.ADD_PROMOTION",
  TABLE_SPLIT_BILL = "TABLE_LIST.SPLIT_BILL",
  TABLE_PRINT_BILL = "TABLE_LIST.PRINT_BILL",
  TABLE_ORDER_FEE = "TABLE_LIST.ORDER_FEE",

  SALES_TODAY = "SALES_RECAPITULATION.TODAY_SALES",
  SALES_HISTORY = "SALES_RECAPITULATION.HISTORY_SALES",
  SALES_REPRINT_RECEIPT = "SALES_RECAPITULATION.REPRINT_RESEND_RECEIPT",
  SALES_EDIT_PAYMENT = "SALES_RECAPITULATION.EDIT_PAYMENT",
  SALES_VOID = "SALES_RECAPITULATION.VOID_SALES",
  SALES_EDIT_REMARKS = "SALES_RECAPITULATION.EDIT_REMARKS",
  SALES_VOID_ITEM = "SALES_RECAPITULATION.VOID_SALES_ITEM",
  SALES_BACKDATE_EDIT_PAYMENT = "SALES_RECAPITULATION.BACKDATE_EDIT_PAYMENT",
  SALES_BACKDATE_VOID = "SALES_RECAPITULATION.BACKDATE_VOID_SALES",
  SALES_BACKDATE_VOID_ITEM = "SALES_RECAPITULATION.BACKDATE_VOID_SALES_ITEM",

  MEMBER_DEPOSIT_LIST = "MEMBER_DEPOSIT.DEPOSIT_LIST",

  SHIFT_VIEW_CURRENT = "SHIFT_IN_OUT.VIEW_CURRENT_SHIFT",
  SHIFT_IN = "SHIFT_IN_OUT.SHIFT_IN",
  SHIFT_END = "SHIFT_IN_OUT.END_SHIFT",
  SHIFT_OUT = "SHIFT_IN_OUT.SHIFT_OUT",
  SHIFT_SHOW_DETAIL = "SHIFT_IN_OUT.SHOW_SHIFT_DETAIL",
  SHIFT_START_DAY_MORE_THAN_ONCE = "SHIFT_IN_OUT.START_DAY_MORE_THAN_1_TIMES",

  SHIFT_LOG_LIST = "SHIFT_LOG.SHIFT_LOG_LIST",
  SHIFT_LOG_DETAIL = "SHIFT_LOG.SHIFT_DETAIL",
  SHIFT_LOG_REPRINT_REPORT = "SHIFT_LOG.REPRINT_SHIFT_REPORT",

  BRANCH_MENU_LIST = "BRANCH_MENU.BRANCH_MENU_LIST",
  BRANCH_MENU_EDIT_QTY = "BRANCH_MENU.EDIT_QTY_SOLD_OUT",
  BRANCH_MENU_EDIT_STATION = "BRANCH_MENU.EDIT_MENU_STATION",

  STATION_LIST = "STATION.STATION_LIST",
  STATION_EDIT = "STATION.EDIT_STATION",

  TOOLS_EDIT_POS = "TOOLS.EDIT_POS_SETTING",
  TOOLS_TEST_PRINT = "TOOLS.TEST_PRINT",
  TOOLS_SYNC_DATA = "TOOLS.SYNCHRONIZE_DATA",
  TOOLS_EDIT_PRINT_SETTING = "TOOLS.EDIT_PRINTING_SETTING",
  TOOLS_REPORTING = "TOOLS.REPORTING",
  TOOLS_PROMOTION_LIST = "TOOLS.PROMOTION_LIST",
}
