import { useSelector } from "react-redux";
import { RootState } from "src/setup";
import { hasPermission } from "../utils/permission";
import { IUser } from "../models/api.types";

export const usePermission = () => {
  const user = useSelector<RootState>(({ auth }) => auth?.user) as unknown as IUser;
  const userPermissions = user?.permissions || [];

  const checkPermission = (requiredPermission: string) => {
    return hasPermission(userPermissions, requiredPermission);
  };

  return { checkPermission, userPermissions };
};
