import {FC, MouseEventHandler} from 'react'
import {GButton, GModal} from '../../Libs'
import GIcon from '../../Libs/Icon/GIcon'
import {Wording} from 'src/app/utils/constants'

interface ModalWarningProps {
  show: boolean
  onClick: MouseEventHandler
  header: string
  positiveLabel?: string
  size?: 'xl' | 'lg' | 'md' | 'sm'
  loading?: boolean
  subHeader?: string
  type: 'WARNING' | 'SUCCESS'
}

const ModalWarning: FC<ModalWarningProps> = ({
  show,
  header,
  onClick,
  subHeader,
  positiveLabel,
  size = 'md',
  loading,
  type = 'WARNING'
}) => {

  const generateIcon = (type: 'WARNING' | 'SUCCESS') =>{
    if(type === 'WARNING') {
      return <GIcon icon='IconAlertYellow' className='h-[48px] w-[48px]' />
    }else{
      return <GIcon icon='IconCheckGreen' className='h-[48px] w-[48px]' />
    }
  }

  return (
    <GModal show={show} size={size}>
      <div className='px-6 py-5 min-h-[100px] text-neutral-80 max-h-[600px] overflow-y-auto w-full'>
        <div className='flex justify-center'>
          <section>
            <div className='w-full flex justify-center'>
              {generateIcon(type)}
            </div>
            <div className='text-neutral-80 w-full flex mt-5 text-center justify-center self-center font-semibold'>{header}</div>
            <div className='flex justify-center text-[#667085] text-[14px] font-normal pt-1'>
              {subHeader}
            </div>
          </section>
        </div>
        <div className='flex w-full gap-3 pt-8 pb-3 '>
          {/* <GButton
            onClick={handleClose}
            type='button'
            size={'large'}
            className='w-full bg-white text-[#344054] border-[#D0D5DD] hover:bg-white'
            data-testid='close-button'
          >
            {negativeLabel ?? Wording.Button.Cancel}
          </GButton> */}

          <GButton
            onClick={onClick}
            type='submit'
            size='large'
            className=' w-full bg-danger border-none hover:bg-danger-600'
            loading={loading}
          >
            {positiveLabel ?? Wording.Button.Sure}
          </GButton>
        </div>
      </div>
    </GModal>
  )
}

export default ModalWarning
